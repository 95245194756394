import { useState, useEffect, useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from '../img/water-drop-white-256.png';

const navigation = [
  { name: 'Home', href: '#' },
  { name: 'Services', href: '#services' },
  { name: 'Our Team', href: '#team' },
];

const weightedTypes = ['circle', 'circle', 'circle', 'circle', 'circle', 'square', 'triangle', 'droplet']; // 5 circles, 1 square, 1 triangle, 1 droplet

const initialShapes = Array.from({ length: 8 }, (_, index) => {
  const x = Math.random() * window.innerWidth;
  return {
    x,
    initialX: x,
    y: Math.random() * 70,
    size: 20 + Math.random() * 15,
    phase: index * 10,
    opacity: Math.random(),
    type: weightedTypes[Math.floor(Math.random() * weightedTypes.length)]
  };
});

export default function NavBar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const canvasRef = useRef(null);
  const navRef = useRef(null);
  // Add state for scroll position (which was missing before)
  const [scrollPosition, setScrollPosition] = useState(0);

  let lastScrollY = 0;
  let isScrolling = false;

  const drawShapes = (shapes) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = navRef.current.offsetHeight;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.lineWidth = 2;

    shapes.forEach((shape) => {
      const { x, y, size, opacity, type } = shape;
      ctx.strokeStyle = `rgba(255, 255, 255, ${opacity})`;
      ctx.beginPath();
      switch (type) {
        case 'square':
          ctx.rect(x, y, size, size);
          break;
        case 'circle':
          ctx.arc(x, y, size / 2, 0, Math.PI * 2);
          break;
        case 'triangle':
          ctx.moveTo(x, y - size / 2);
          ctx.lineTo(x + size / 2, y + size / 2);
          ctx.lineTo(x - size / 2, y + size / 2);
          ctx.closePath();
          break;
        case 'droplet':
          const dropletPath = new Path2D("M29 0C46 23 56 35 56 46C56 59 45 70 29 70C13 70 2 59 2 46C2 35 12 23 29 0Z");
          const scaleFactor = size / 50; // assuming the SVG width is 50, adjust if needed
          ctx.save();
          ctx.translate(x, y);
          ctx.scale(scaleFactor, scaleFactor);
          ctx.translate(-25, -40); // assuming SVG width is 50 and height is 80, adjust if needed
          ctx.stroke(dropletPath);
          ctx.restore();
          break;
        default:
          break;
      }
      ctx.stroke();
    });
  };

  const animate = (time) => {
    const scrollY = window.scrollY;
    const diff = Math.abs(scrollY - lastScrollY);
    isScrolling = diff !== 0;
  
    initialShapes.forEach((shape) => {
      if (isScrolling) {
        shape.y -= 1;
        shape.opacity = 0.25 + Math.sin(time * 0.002 + shape.phase) * 0.20;
      } else {
        // If not scrolling, move the shapes very slowly
        shape.y -= 0.1; // Adjust this value to change the speed
        shape.opacity = 0.25 + Math.sin(time * 0.0002 + shape.phase) * 0.20; // Adjust this to change the opacity oscillation speed
      }
      
      shape.x = shape.initialX + Math.sin(shape.y * 0.02 + shape.phase) * 50;
  
      if (shape.y < -shape.size) {
        shape.y = 70;
        shape.initialX = shape.x = Math.random() * window.innerWidth;
        shape.phase = Math.random() * 100;
      }
    });
  
    lastScrollY = scrollY;
    drawShapes(initialShapes);
    requestAnimationFrame(animate);
  };

  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (navRef.current) {
      const hueShift1 = Math.sin(scrollPosition * 0.005) * 5;
      const hueShift2 = Math.sin((scrollPosition + 50) * 0.005) * 5;
      const color1 = `hsl(${200 + hueShift1}, 50%, 15%)`;
      const color2 = `hsl(${210 + hueShift2}, 50%, 20%)`;
      navRef.current.style.background = `linear-gradient(to right, ${color1}, ${color2})`;
    }
  }, [scrollPosition]);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.height = navRef.current.offsetHeight;
    requestAnimationFrame(animate);
  }, []);

  useEffect(() => {
    if (navRef.current) {
      const hueShift1 = Math.sin(scrollPosition * 0.005) * 5;
      const hueShift2 = Math.sin((scrollPosition + 50) * 0.005) * 5;
      const color1 = `hsl(${200 + hueShift1}, 50%, 15%)`;
      const color2 = `hsl(${210 + hueShift2}, 50%, 20%)`;
      navRef.current.style.background = `linear-gradient(to right, ${color1}, ${color2})`;
    }
  }, [scrollPosition]);
  
  const handleMobileLinkClick = () => setMobileMenuOpen(false);

  return (
    <header className="fixed top-0 w-full z-50 shadow-md">
      {/* This div will have the dynamic background */}
      <div 
        ref={navRef} 
        className="absolute top-0 left-0 w-full h-full z-0"
      ></div>
      
      {/* Canvas is now transparent and above the background div */}
      <canvas ref={canvasRef} id="headerCanvas" className="absolute top-0 left-0 z-10"></canvas>
      
      {/* Nav is transparent and at the top */}
      <nav className="sticky mx-auto flex max-w items-center justify-between p-4 bg-transparent z-20">
        <div className="flex lg:flex-1">
          <a href="/">
            <img className="h-8 w-auto" src={logo} alt="" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(true)}>
            <Bars3Icon className="stroke-white h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className="font-body text-sm font-semibold leading-6 text-white transition-colors duration-200 hover:text-slate-300">
              {item.name}
            </a>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="#contact" className="button-navbar">
            Contact Us
          </a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10 bg-gray-900 bg-opacity-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gradient-to-r from-slate-900 to-slate-800 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-between mb-6">
                <a href="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">Liquid App Labs</span>
                  <img className="h-8 w-auto" src={logo} alt="" />
                </a>
                <button type="button" className="-m-2.5 rounded-md p-2.5 inline-flex items-center justify-center text-gray-700" onClick={() => setMobileMenuOpen(false)}>
                  <XMarkIcon className="stroke-white h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex-grow flex flex-col items-center justify-center space-y-4">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} onClick={handleMobileLinkClick} className="flex justify-center p-2 rounded-md text-white hover:text-slate-300">
                    {item.name}
                  </a>
                ))}
                <a href="#contact" onClick={handleMobileLinkClick} className="button-navbar">
                  Contact Us
                </a>
              </div>
            </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
