import { useEffect, useRef } from 'react';

export default function HeroSection() {
    const imageRef = useRef();

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const translateValue = scrollTop * 0.6;
            imageRef.current.style.transform = `translateY(${translateValue}px)`;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div id='home' className='relative isolate overflow-hidden bg-gray-900 pb-16 pt-14 sm:pb-20'>
            <img
                ref={imageRef}
                src='https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply'
                alt=''
                className='absolute inset-0 -z-10 h-full w-full object-cover'
            />
            <div className='absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80'>
                <div
                    className='relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]'
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                <div className='mx-auto max-w-2xl py-32 sm:py-48 lg:py-56'>
                    <div className='text-center'>
                    <h1 className='text-4xl font-bold tracking-light text-white sm:text-6xl'>
                        <span className='sm:hidden'>EMPOWER YOUR<br />BUSINESS WITH<br /></span>
                        <span className='hidden sm:inline'>EMPOWER YOUR BUSINESS WITH </span>
                        LIQUID APP LABS
                    </h1>
                        <p className="mt-6 text-xl leading-8 text-gray-300">
                            <span className="hidden sm:inline">Tailored Digital Solutions for </span>
                            <span className="sm:hidden">Tailored Digital Solutions for<br /></span>
                            Growth and Success
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a
                                href="#services"
                                className="button-hero"
                            >
                                Discover Our Services
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}