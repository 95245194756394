import { EnvelopeIcon } from '@heroicons/react/24/outline'

const FORM_ENDPOINT = 'https://eovim0ul6fambh0.m.pipedream.net'

export default function ContactUsSection() {
    return (
        <div id='contact' className='relative isolate bg-gray-900'>
            <div className='mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2'>
                <div className='relative px-6 pb-5 pt-24 sm:pt-32 lg:static lg:px-9 lg:py-48'>
                    <div className='absolute inset-y-0 left-0 -z-10 w-full overflow-hidden ring-1 ring-white/5 lg:w-1/2'>
                        <svg
                            className="absolute inset-0 h-full w-full stroke-gray-700 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                            aria-hidden="true"
                        >
                            <defs>
                                <pattern
                                    id="54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2"
                                    width={200}
                                    height={200}
                                    x="100%"
                                    y={-1}
                                    patternUnits="userSpaceOnUse"
                                >
                                <path d="M130 200V.5M.5 .5H200" fill="none" />
                            </pattern>
                            </defs>
                            <svg x="100%" y={-1} className="overflow-visible fill-gray-800/20">
                                <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                            </svg>
                            <rect width="100%" height="100%" strokeWidth={0} fill="url(#54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2)" />
                        </svg>
                        <div
                            className="absolute -left-56 top-[calc(100%-13rem)] transform-gpu blur-3xl lg:left-[max(-14rem,calc(100%-59rem))] lg:top-[calc(50%-7rem)]"
                            aria-hidden="true"
                        >
                            <div
                                className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-br from-[#80caff] to-[#4f46e5] opacity-20"
                                style={{
                                    clipPath:
                                        'polygon(74.1% 56.1%, 100% 38.6%, 97.5% 73.3%, 85.5% 100%, 80.7% 98.2%, 72.5% 67.7%, 60.2% 37.8%, 52.4% 32.2%, 47.5% 41.9%, 45.2% 65.8%, 27.5% 23.5%, 0.1% 35.4%, 17.9% 0.1%, 27.6% 23.5%, 76.1% 2.6%, 74.1% 56.1%)',
                                }}
                            />
                        </div>
                    </div>
                    <div className='mx-auto max-w-xl lg:mx-0 lg:max-w-lg'>
                        <h2 className='text-3xl font-bold tracking-light text-white mb-8'>
                            Get in touch
                        </h2>
                        <p className='text-lg leading-8 text-gray-300 mb-12'>
                            Connect with our team of experts to discuss your needs and explore how we can help your business thrive in the digital landscape.
                        </p>
                        <dl className='space-y-4 text-base leading-7 text-gray-300'>
                            <div className='flex gap-x-4'>
                                <dt className='flex-none'>
                                    <span className='sr-only'>Mail</span>
                                    <EnvelopeIcon className='h-7 w-6 text-blue-500' aria-hidden='true' />
                                </dt>
                                <dd>
                                    <a className='hover:text-white text-blue-500' href='mailto:info@liquidapplabs.com'>
                                        info@liquidapplabs.com
                                    </a>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <form
                    action={FORM_ENDPOINT}
                    method="POST"
                    className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48"
                >
                    <div className='mx-auto max-w-xl lg:mr-0 lg:max-w-lg'>
                        <div className='grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2'>
                            <div>
                                <label htmlFor='first-name' className='label-input'>
                                    First name
                                </label>
                                <div className='mt-2.5'>
                                    <input 
                                        type='text'
                                        name='first-name'
                                        id='first-name'
                                        autoComplete='given-name'
                                        className='text-input'
                                        placeholder='First name'
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor='last-name' className='label-input'>
                                    Last name
                                </label>
                                <div className='mt-2.5'>
                                    <input 
                                        type='text'
                                        name='last-name'
                                        id='last-name'
                                        autoComplete='family-name'
                                        className='text-input'
                                        placeholder='Last name'
                                    />
                                </div>
                            </div>
                            <div className='sm:col-span-2'>
                                <label htmlFor='email' className='label-input'>
                                    Email
                                </label>
                                <div className='mt-2.5'>
                                    <input 
                                        type='email'
                                        name='email'
                                        id='email'
                                        autoComplete='email'
                                        className='text-input'
                                        placeholder='Email'
                                    />
                                </div>
                            </div>
                            <div className='sm:col-span-2'>
                                <label htmlFor='phone-number' className='label-input'>
                                    Phone number
                                </label>
                                <div className='mt-2.5'>
                                    <input 
                                        type='tel'
                                        name='phone-number'
                                        id='phone-number'
                                        autoComplete='tel'
                                        className='text-input'
                                        placeholder='Phone number'
                                    />
                                </div>
                            </div>
                            <div className='sm:col-span-2'>
                                <label htmlFor='message' className='label-input'>
                                    Message
                                </label>
                                <div className='mt-2.5'>
                                    <textarea 
                                        name='message'
                                        id='message'
                                        rows={4}
                                        autoComplete='tel'
                                        className='text-input'
                                        defaultValue={''}
                                        placeholder='Your awesome message begins here...'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 flex justify-end">
                            <button
                                type="submit"
                                className="rounded-full bg-blue-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                            >
                                Send message
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}