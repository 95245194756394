import PillTitle from '../components/PillTitle'
import HenryImg from '../img/henry-headshot.jpg'
import JocelynImg from '../img/jocelyn-headshot.jpg'
import AndrewImg from '../img/andrew-headshot.jpg'

const team_members = [
  /*{
    name: 'Henry Thomas',
    description:
      "Hey, I'm Henry, a software engineer with 10+ years of experience working across finance, advertising, and real estate. I'm skilled in problem-solving and various programming languages, delivering innovative solutions, and exceptional customer service.",
    icon: HenryImg,
  },*/
  {
    name: 'Jocelyn Ragukonis',
    description:
      "Hello, I'm Jocelyn, a machine learning engineer and data scientist who is passionate about using data to help businesses solve their operational challenges and reach new growth levels. I earned my Masters Degree in AI from Stevens, and went on to build two startups.",
    icon: JocelynImg,
  },
  {
    name: 'Andrew Zhao',
    description:
      "Hi, I'm Andrew. I've had a successful B2B sales career. I'm a self-taught engineer and lifelong builder. I've also founded my own startup, which I built into a self-sustaining company. I'm most looking forward to helping our clients grow their businesses and creating mutual success.",
    icon: AndrewImg,
  },
]

export default function TeamSection() {
  return (
    <div id='team' className='bg-slate-900 py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <PillTitle content='Who We Are' className='alt-section-title-pill' />
        <div className="mx-auto max-w-2xl lg:text-center">

          <h1 className='mt-10 text-3xl font-bold tracking-light text-slate-100 sm:text-4xl'>
            Meet The Team
          </h1>
          <p className="mt-6 text-lg leading-8 text-slate-200">
            Our team is small but mighty, and we pride ourselves on our ability to work effectively to deliver exceptional results.
          </p>
        </div>
        <div className='mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl'>
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-2 lg:gap-y-16">
            {team_members.map((member) => (
              <div key={member.name} className="font-body text-center">
                <dt className="text-base font-semibold leading-7 text-slate-200">
                  <img className="rounded-md mx-auto" src={member.icon} alt={`Headshot of ${member.name}`} />
                  {member.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-slate-200">
                  {member.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
