import { useState, useEffect } from 'react';

export default function Modal({ isOpen, onClose, children }) {
  const handleOverlayClick = (event) => {
    event.stopPropagation();
    onClose();
  };

  const handleDialogClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const stateParam = urlParams.get('state');
    if (stateParam === 'thank-you') {
      onClose();
    }
  }, []);

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? 'block' : 'hidden'}`}
      onClick={handleOverlayClick}
    >
      <div
        className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          onClick={handleDialogClick}
          style={{ maxHeight: '60vh', overflowY: 'auto', padding: '1em' }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
