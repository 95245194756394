import { useState, useEffect } from 'react';
import Modal from '../components/Modal';
import NavBar from '../components/NavBar'
import HeroSection from '../sections/HeroSection'
import ServicesSection from '../sections/ServicesSection'
import TeamSection from '../sections/TeamSection'
import TestimonialsSection from '../sections/TestimonialsSection'
import ContactUsSection from '../sections/ContactUsSection'


export default function HomePage() {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const stateParam = urlParams.get('state');

        if (stateParam === 'thank-you') {
        setIsOpen(true);
        }
    }, []);
    
    return (
        <div className='bg-white'>
            <NavBar />
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h1 className="text-lg leading-6 font-medium text-gray-900">Thank you!</h1>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">Your information has been received. We will reach out to you shortly.</p>
                    </div>
                    </div>
                </div>
            </Modal>
            <HeroSection />
            <ServicesSection />
            <TeamSection />
            <TestimonialsSection />
            <ContactUsSection />
        </div>
    )
}