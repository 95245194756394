import React, { useState } from 'react';
import Modal from '../components/Modal';
import PillTitle from '../components/PillTitle'
import ClientAImg from '../img/wbd-logo-square.png'

const testimonial = {
    name: 'Writing by Design',
    feedback:
      ' Liquid Labs is an incredibly talented team, and I am thrilled to provide testimony to the same. Liquid Labs team members have an amazing ability to identify and address specific business needs with a consultative approach.',
      fullFeedback: 
      'Liquid Labs is an incredibly talented team, and I am thrilled to provide testimony to the same. Liquid Labs team members have an amazing ability to identify and address specific business needs with a consultative approach.\n\n' +
      'They very quickly, and diligently, acquired an understanding of developing positive connections within the educational environment to promote our company mission. With sensitivity to our current capacity and business goals, they crafted a tailor-made marketing strategy that exceeded expectations.\n\n' +
      'Their expertise in managing key metrics and analytics with various marketing channels, including digital, social media, and traditional methods, is helping us reach a broader audience to drive tangible results. In a short time, we are experiencing a substantial increase in website traffic and conversions.\n\n' +
      'Their innovative ideas and techniques have infused new life into our company, enhancing its visibility and increasing operational effectiveness. Their IT development expertise has led to the automatization of regular tasks, freeing employees to achieve greater productivity.\n\n' +
      'Throughout our frequent collaboration, the team displays exceptional professionalism and communication skills. They are consistently responsive, attentive, and open to feedback, ensuring a seamless partnership. Their attention to detail and commitment to delivering high-quality work has been evident in every aspect of their services.\n\n' +
      'I wholeheartedly recommend Liquid Labs to any organization seeking exceptional marketing services. Their ability to drive results, combined with their passion for their craft, make them a truly valuable partner in achieving your business objectives.',
    author: 'Dr. Karen Dubrule, President',
    icon: ClientAImg,
}

export default function TestimonialsSection() {
    const [isOpen, setIsOpen] = useState(false);

    // Split the fullFeedback into paragraphs
    const feedbackParagraphs = testimonial.fullFeedback.split('\n\n');

    return (
        <div id='testimonials' className='bg-white py-24 sm:py-32'>
            <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                <PillTitle content='Testimonials' className='section-title-pill' />
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h1 className='mt-10 text-3xl font-bold tracking-light text-slate-900 sm:text-4xl'>
                        What Our Clients Say
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-slate-850">
                        Our clients love our results!
                    </p>
                </div>
                <div className='mx-auto mt-8 max-w-2xl sm:mt-20 lg:mt-16 lg:max-w-4xl'>
                    <div className="lg:grid lg:grid-cols-3 lg:gap-6 items-center text-center lg:text-left">
                        <div className="lg:col-span-1">
                            <img className="rounded-md mx-auto lg:mx-0 h-48 w-48 object-cover object-center" src={testimonial.icon} alt={`Logo of ${testimonial.name}`} />
                        </div>
                        <div className="lg:col-span-2">
                            <dt className="text-base font-semibold font-body leading-7 text-slate-900 mt-4 lg:mt-0">
                                {testimonial.name}
                            </dt>
                            <dd className="mt-2 font-body text-base leading-7 text-slate-900">
                                {testimonial.feedback}
                            </dd>
                            <a href="#" onClick={(e) => { e.preventDefault(); setIsOpen(true); }} className="text-blue-500 hover:text-blue-800">Read full review</a>
                            <p className="mt-2 text-base leading-7 font-body text-slate-900s font-medium">
                                - {testimonial.author}
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} title={`Testimonial by ${testimonial.name}`}>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h1 className="text-lg leading-6 font-medium text-gray-900">Writing by Design Testimonial</h1>
                    <div className="mt-2">
                        {feedbackParagraphs.map((paragraph, index) => (
                            <p key={index} className="text-sm text-gray-500 my-2">{paragraph}</p>
                        ))}
                        <p className="mt-3 text-sm text-gray-500 text-right">- {testimonial.author}</p>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
