import { LightBulbIcon, DocumentChartBarIcon, GlobeAltIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline'
import PillTitle from '../components/PillTitle'

const features = [
  {
    name: 'Web Development',
    description:
      'Custom web solutions tailored to your business needs, from simple websites to advanced web applications that enhance user experience.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Digital Marketing',
    description:
      'Expert marketing strategies to boost your online presence, increase customer engagement, and drive traffic with proven techniques.',
    icon: ArrowTrendingUpIcon,
  },
  {
    name: 'Consulting & Strategy',
    description:
      'Comprehensive consulting services to identify opportunities for growth, optimize processes, and develop actionable plans for success.',
    icon: LightBulbIcon,
  },
  {
    name: 'Data & Analytics',
    description:
      'Leverage data-driven insights to make informed decisions, improve performance, and gain a competitive edge in the market.',
    icon: DocumentChartBarIcon,
  },
]

export default function ServicesSection() {
  return (
    <div id='services' className='bg-white py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <PillTitle content='What We Do' className='section-title-pill' />
        <div className="mx-auto max-w-2xl lg:text-center">

          <h1 className='mt-10 text-3xl font-bold tracking-light text-slate-900 sm:text-4xl'>
            Comprehensive Digital Solutions
          </h1>
          <p className="mt-6 text-lg leading-8 text-slate-850">
            Our suite of digital services is designed to help businesses grow, succeed, and adapt in an ever-changing online landscape.
          </p>
        </div>
        <div className='mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl'>
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="font-body relative pl-16">
                <dt className="text-base font-semibold leading-7 text-slate-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center   justify-center rounded-lg bg-blue-500">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-slate-850">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
